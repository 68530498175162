import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const INTERVAL = 1000;

export const ProfileComponent = () => {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [tokenResponse, setTokenResponse] = useState();
  const [tokenValidFor, setTokenValidFor] = useState();
  useEffect(() => {
   getAccessTokenSilently({detailedResponse: true}).then(response => {
    setTokenResponse(response)
    const access_token = response.access_token;
        const claims = parseClaims(access_token);
        const validFor = Math.floor((new Date(claims.exp * 1000).getTime() - new Date().getTime()) / 1000)
        if (validFor) {
          setTokenValidFor(validFor);
        }
   }).catch(error => {
    console.log(error);
    loginWithRedirect({})
   })
    
  }, [getAccessTokenSilently, loginWithRedirect, setTokenResponse])

  
  const parseClaims = (token) => {
    const parts = token.split(".");
    if (parts.length === 3) {
      try {
        const payload = atob(parts[1]);
        return JSON.parse(payload) || {};
      } catch (error) {
        console.log(error, token)
        return {}
      }
    }
    return {}
  }
  useEffect(() => {
    setInterval(() => {
      getAccessTokenSilently({ detailedResponse: true })
        .then((response) => {
          setTokenResponse(response);
          const access_token = response.access_token;
          const claims = parseClaims(access_token);
          const validFor = Math.floor((new Date(claims.exp * 1000).getTime() - new Date().getTime()) / 1000);
          if (validFor) {
            setTokenValidFor(validFor);
          }
        })
        .catch((error) => {
          console.log(error);
          loginWithRedirect({});
        });
    }, INTERVAL)
  }, [getAccessTokenSilently, loginWithRedirect])
  
  return (
    <Container className="mb-5 profile-page-dark">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img src={user.picture} alt="Profile" className="rounded-circle img-fluid profile-picture mb-3 mb-md-0" />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Col md>
          <h2>Token Response</h2>
          <Highlight>{JSON.stringify(tokenResponse || {}, null, 2)}</Highlight>
          <h4>Access token is still valid for {tokenValidFor} seconds.</h4>
        </Col>
      </Row>
      <Row>
        <Col md>
          <h2>Access Token Claims</h2>
          <Highlight>{JSON.stringify(parseClaims(tokenResponse?.access_token || ""), null, 2)}</Highlight>
        </Col>
      </Row>
      <Row>
        <Col md>
          <h2>Id Token Claims</h2>
        </Col>{" "}
        <Highlight>{JSON.stringify(parseClaims(tokenResponse?.id_token || ""), null, 2)}</Highlight>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
