import React from "react";
import { Container, Row, Col } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

import {Button} from "reactstrap"

/**
 * E01 : Verified Email missing
 * E02 : Consent Missing 
 */

export const ErrorComponent = () => {
  const { loginWithRedirect, error } = useAuth0();

  return (
    <Container className="mb-5 profile-page-dark">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md className="errorItems">
          <h2>Error</h2>
          <p>{JSON.stringify(error)}</p>
          <p>{error.error}</p>
          <p>{error.error_description}</p>

          {error.error_description && error.error_description.indexOf("E01") > -1 ? (
            <Button color="primary">
              Resend Verification Email
            </Button>
          ) : (
            ""
          )}

          <Button
            id="qsLoginBtn"
            className="errorLoginButton"
            color="primary"
            block
            onClick={() => loginWithRedirect({})}
          >
            Log in
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
