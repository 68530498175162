import React from "react";


const Hero = () => (
  <div className="app-grid">
    <div className="grid-tile">
      <a href="https://play-suisse.ciam.training" target="_blank" rel="noreferrer">
        <img
          src="https://www.srgssr.ch/typo3conf/ext/is_design/Resources/Public/imgs/logo_play_suisse.svg"
          alt="play-suisse-logo"
        />
      </a>
      <div className="tile-footer">
        <h4>Play Suisse</h4>
        <p>Your favorite movies & shows</p>
      </div>
    </div>
    <div className="grid-tile">
      <a href="https://srf.ciam.training" target="_blank" rel="noreferrer">
        <img src="https://www.srgssr.ch/typo3conf/ext/is_design/Resources/Public/imgs/logo_srf.svg" alt="srf-logo" />
      </a>
      <div className="tile-footer">
        <h4>SRF Website</h4>
        <p>The latest news from srf</p>
      </div>
    </div>
    <div className="grid-tile">
      <a href="https://rts.ciam.training" target="_blank" rel="noreferrer">
        <img src="https://www.srgssr.ch/typo3conf/ext/is_design/Resources/Public/imgs/logo_rts.svg" alt="rts-logo" />
      </a>
      <div className="tile-footer">
        <h4>RTS Website</h4>
        <p>The latest news from rts</p>
      </div>
    </div>
  </div>
);

export default Hero;
